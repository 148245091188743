import { LoginAdminComponent } from './views/user-account/login-admin/login-admin.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { LoginGuard } from './shared/guard/login.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/homepage/homepage.module')
        .then((m) => m.HomepageModule)
  },
  {
    path: 'phim/:id',
    loadChildren: () =>
      import('./views/detail/detail.module')
        .then((m) => m.DetailModule)
  },
  {
    path: 'login',
    component: LoginAdminComponent
  },
  {
    path: 'admin',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./admin/admin.module')
        .then((m) => m.AdminModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
