<form class="form-signin mt-5" [formGroup]="formAdminSignin" (submit)="handleLogin()">
    <h1 class="h3 mb-3 text-center">sign in</h1>

    <ng-template #passMsg>
        <p class="msg"><i class="fas fa-info-circle"></i> {{message}}</p>
    </ng-template>
    <div class="error mb-3" *ngIf="error"><i class="fas fa-exclamation-triangle"></i> {{error}}</div>

    <label for="inputUsername" class="sr-only">Username</label>
    <input formControlName="taiKhoan" type="text" id="inputUsername" class="form-control mb-2" placeholder="Username"
        required autofocus>
    <label for="inputPassword" class="sr-only">Password</label>
    <input formControlName="matKhau" type="password" id="inputPassword" class="form-control" placeholder="Password"
        required>
    <button class="btn btn-lg btn-primary btn-block" type="submit">Login</button>
    <p class="mt-5 mb-3 text-center">&copy; 2020 - Kien Pham</p>
</form>