import { Router } from '@angular/router';
import { MoviesService } from './../../services/movies.service';
import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit, OnChanges {

  constructor(
    // tslint:disable:variable-name
    private _moviesService: MoviesService,
    private router: Router,
  ) { }

  nowShowingMovies = [];
  keyword = 'tenPhim';
  @ViewChild('searchMovies') searchMovies;

  ngOnInit(): void {

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  selectEvent(item) {
    this.redirectTo('/phim/' + item.maPhim);
  }

  onChangeSearch(e) {
    this.nowShowingMovies = this._moviesService.nowShowingMovies;
  }

  onFocused(e) {
    this.searchMovies.clear();
    this.searchMovies.close();
  }

  ngOnChanges(): void {
  }

}
