import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) { }

  public open(message, action = 'success', duration = 3000) {
    this.zone.run(() => {
      this.snackBar.open(message, action,
        { duration, horizontalPosition: this.horizontalPosition, verticalPosition: this.verticalPosition, panelClass: ['snackbar'] });
    });
  }
}
