import { Subject } from 'rxjs';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MoviesService {

  // tslint:disable-next-line:variable-name
  constructor(private _http: HttpClient) { }

  fromDate = '01/01/2000';
  toDate = '30/12/2020';
  comingSoonDate = '01/04/2020';
  // fromDate = moment().format('DD/MM/YYYY'); // get current day (today)
  // toDate: string = moment().add(14, 'days').format('DD/MM/YYYY'); // next 2 weeks


  nowShowingMovies = [];
  @Output() nowShowingMoviesEmitter = new EventEmitter();

  comingSoonMovies = [];
  @Output() comingSoonMoviesEmitter = new EventEmitter();

  movieDetail: any = {};
  @Output() movieDetailEmitter = new EventEmitter();

  trailerURL: Subject<string> = new Subject<string>();

  // ##### Movies Trailer URL ##### //
  getTrailerURL(url) {
    this.trailerURL.next(url);
  }


  // ##### Movies Details ##### //
  resetMovieDetail() {
    this.movieDetail = {};
  }

  setMovieDetail(value) {
    this.movieDetail = value;
    this.movieDetailEmitter.emit(this.movieDetail);
  }

  getMovieDetail(movieID: number) {
    return this._http.get(
      'https://movie0706.cybersoft.edu.vn/api/QuanLyRap/LayThongTinLichChieuPhim?MaPhim=' + movieID
    );
  }



  // ##### Now Showing Movies ##### //
  setNowShowingMovies(value) {
    this.nowShowingMovies = value;
    this.nowShowingMoviesEmitter.emit(this.nowShowingMovies);
  }

  fetchNowShowingMovies() {
    return this._http.get(
      `https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhimTheoNgay?maNhom=GP09&soTrang=1&soPhanTuTrenTrang=10&tuNgay=${this.fromDate}&denNgay=${this.toDate}`
    );
  }



  // ##### Coming Soon Movies ##### //
  setComingSoonMovies(value) {
    this.comingSoonMovies = value;
    this.comingSoonMoviesEmitter.emit(this.comingSoonMovies);
  }

  fetchComingSoonMovies() {
    return this._http.get(
      `https://movie0706.cybersoft.edu.vn/api/QuanLyPhim/LayDanhSachPhimTheoNgay?maNhom=GP09&soTrang=1&soPhanTuTrenTrang=10&tuNgay=${this.comingSoonDate}&denNgay=${this.toDate}`
    );
  }



}
