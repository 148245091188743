<div class="modal-body" *ngIf="accountData; else loading">
    <button class="modal-close" (click)="close()"></button>
    <div class="container">
        <div class="row">
            <div class="col-12 p-5">
                <p *ngIf="!message; else passMsg">Update your account information or checking your booking history.</p>
                <ng-template #passMsg>
                    <p class="msg"><i class="fas fa-info-circle"></i> {{message}}</p>
                </ng-template>
                <div class="error" *ngIf="error"><i class="fas fa-exclamation-triangle"></i> {{error}}</div>
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Account Settings</a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="formAccount">
                                <div class="form-group">
                                    <input type="text" placeholder="Username" [(ngModel)]="accountData.taiKhoan"
                                        formControlName="taiKhoan" class="form-control w-25" disabled>
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Password" [(ngModel)]="accountData.matKhau"
                                        formControlName="matKhau"
                                        [ngClass]="{ 'is-invalid': submitted && fAcc.matKhau.errors }"
                                        class="form-control w-25">
                                </div>
                                <div class="form-group">
                                    <input type="email" placeholder="Email address" [(ngModel)]="accountData.email"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && fAcc.email.errors }"
                                        class="form-control w-25">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Full Name" [(ngModel)]="accountData.hoTen"
                                        formControlName="hoTen"
                                        [ngClass]="{ 'is-invalid': submitted && fAcc.hoTen.errors }"
                                        class="form-control w-25">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Phone Number" [(ngModel)]="accountData.soDT"
                                        formControlName="soDt"
                                        [ngClass]="{ 'is-invalid': submitted && fAcc.soDt.errors }"
                                        class="form-control w-25">
                                </div>
                                <div class="form-group">
                                    <button class="btn_red2" type="button" (click)="updateAccount()">Save</button>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Booking History</a>
                        <ng-template ngbNavContent>
                            <table class="table table-striped"
                                [ngStyle]="accountData.thongTinDatVe.length != 0 && {display: 'block'}">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Movie</th>
                                        <th scope="col">Book Date</th>
                                        <th scope="col">Cinema</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="accountData.thongTinDatVe.length != 0; else noRecords">
                                    <tr *ngFor="let item of accountData.thongTinDatVe.slice().reverse(); let i = index">
                                        <th scope="row">{{ i + 1}}</th>
                                        <td>
                                            <ngb-highlight [result]="item.tenPhim">
                                            </ngb-highlight>
                                        </td>
                                        <td>
                                            <ngb-highlight [result]="item.ngayDat | date: 'dd/MM/yyyy HH:mm'">
                                            </ngb-highlight>
                                        </td>
                                        <td>
                                            <ngb-highlight [result]="item.danhSachGhe[0].tenHeThongRap">
                                            </ngb-highlight>
                                        </td>
                                    </tr>
                                </tbody>
                                <ng-template #noRecords>
                                    <tbody>
                                        <tr>
                                            <td colspan="4">You haven't booked any ticket.</td>
                                        </tr>
                                    </tbody>
                                </ng-template>
                            </table>

                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
            </div>
            <!-- <div class="col-7 loginBG"></div> -->
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="modal-body p-5 text-center">
        <button class="modal-close" (click)="close()"></button>
        <p>One moment...</p>
        <div class="fa fa-spinner fa-spin"></div>
    </div>
</ng-template>