import { LazyLoadDirective } from './lazyload.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [LazyLoadDirective],
  imports: [
    CommonModule
  ],
  exports: [
    LazyLoadDirective
  ]
})
export class LazyloadModule { }
