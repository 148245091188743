<div class="account">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle menu-clicked" (click)="changeLogin()" id="navbardrop" data-toggle="dropdown">
            <i class="fas fa-user-circle"></i>
        </a>
        <div class="dropdown-menu small-menu">
            <ng-container *ngIf="isLogin; else notLogin">
                <a (click)="open(accountProfile, 2)" class="no-icon">Booking History</a>
                <a (click)="open(accountProfile, 1)" class="no-icon">Your Account</a>
                <a routerLink="./admin/users" class="no-icon" *ngIf="isAdmin" target="_blank">Admin Panel</a>
                <a (click)="logout()">Logout</a>
            </ng-container>
            <ng-template #notLogin>
                <a (click)="open(loginRegister, 2)" class="no-icon">Login</a>
                <a (click)="open(loginRegister, 1)" class="no-icon">Register</a>
            </ng-template>
        </div>
    </li>
</div>

<ng-template #loginRegister let-modal>
    <app-login-register-form [activeTabId]="activeTabId"></app-login-register-form>
</ng-template>
<ng-template #accountProfile let-modal>
    <app-account-profile-index [activeTabId]="activeTabId"></app-account-profile-index>
</ng-template>