import { Router } from '@angular/router';
import { AccountService } from './../../../services/account.service';
import { AuthService } from './../../../services/auth.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {
  formAdminSignin: FormGroup;
  submitted = false;
  message: string;
  error: string;

  constructor(
    // tslint:disable:variable-name
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _accountService: AccountService,
    private router: Router,
    private zone: NgZone
  ) {
    this._authService.passMessage.subscribe(msg => {
      this.message = msg;
    });
  }

  ngOnInit(): void {
    this.formAdminSignin = this._formBuilder.group({
      taiKhoan: new FormControl('', [Validators.required]),
      matKhau: new FormControl('', [Validators.required])
    });

    // if (this._authService.isLogin && (JSON.parse(localStorage.getItem('credentials')).maLoaiNguoiDung === 'QuanTri')) {
    //   this.router.navigate(['./']);
    // } else {
    //   this.router.navigate(['admin/users']);
    // }
  }

  get fLgi() { return this.formAdminSignin.controls; }

  handleLogin() {
    // console.log(this.formAdminSignin.value);

    this.submitted = true;
    // tslint:disable-next-line:curly
    if (this.formAdminSignin.invalid) return;

    this._accountService.signIn(this.formAdminSignin.value)
      .subscribe(
        (res: any) => {
          if (res.maLoaiNguoiDung === 'QuanTri') {
            localStorage.setItem('credentials', JSON.stringify(res));
            localStorage.setItem('accessToken', res.accessToken);
            localStorage.setItem('isAdmin', 'yes');
            this.router.navigate(['admin/users']);
          } else {
            localStorage.clear();
            this.error = 'You don\'t have permission to access this page';
            return;
          }
        },
        (err) => {
          console.log(err);
          this.error = err.error;
        }
      );
  }

}
