<footer>
    <div class="footer-top">
        <div class="contact">
            <div class="social">
                <a href=""><i class="fab fa-facebook-f"></i></a>
                <a href=""><i class="fab fa-youtube"></i></a>
            </div>
            <div class="subscribe">
                © 2020 Kien Pham
            </div>
        </div>
    </div>
</footer>