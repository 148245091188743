<header>
    <nav class="navbar navbar-expand-sm">
        <nav role="navigation" class="mobileMenu">
            <div id="menuToggle">
                <input type="checkbox">
                <span></span>
                <span></span>
                <span></span>
                <ul id="mobi-nav" class="mb-menu">
                    <li><a routerLink="/">Home</a></li>
                    <li><a>Movies</a></li>
                    <li><a>Cinema</a></li>
                    <li><a>About</a></li>
                    <li><a>Contact</a></li>
                </ul>
            </div>
        </nav>
        <!-- Brand -->
        <a class="navbar-brand" routerLink="/">
            movix
        </a>

        <!-- Links -->
        <ul class="navbar-nav float-right">
            <li class="nav-item dropdown movies_mega">
                <a class="nav-link main dropdown-toggle menu-clicked" id="navbardrop" data-toggle="dropdown">Movies</a>
                <div class="dropdown-menu" id="moviestab">
                    <div class="card card-body vehicles_menu" data-spy="scroll" data-target=".navbar" data-offset="50">
                        <div id="movies_body" class="movies_body ">
                            <section id="movies">
                                <div class="item">
                                    <h4>Blockbuster</h4>
                                    <div class="featured">

                                        <img src="./../../../assets/images/Avengers_Endgame.jpg" alt="">
                                        <div class="details">
                                            <h5>Avengers Endgame</h5>
                                            <span>April 22, 2019 | IMDB: 8.9</span>
                                            <p>After Thanos, an intergalactic warlord, disintegrates half of the
                                                universe, the Avengers must reunite and assemble again to reinvigorate
                                                their trounced allies and restore balance.
                                            </p>
                                        </div>

                                    </div>

                                </div>
                                <div class="item">
                                    <h4>Genres</h4>
                                    <ul class="menu">
                                        <li><a routerLink="">Action</a></li>
                                        <li><a routerLink="">Adventure</a></li>
                                        <li><a routerLink="">Comedy</a></li>
                                        <li><a routerLink="">Drama</a></li>
                                        <li><a routerLink="">Sci-Fi</a></li>
                                        <li><a routerLink="">Thriller</a></li>
                                        <li><a routerLink="">Biography</a></li>
                                        <li><a routerLink="">Family</a></li>
                                        <li><a routerLink="">Romance</a></li>
                                        <li><a routerLink="">Animation</a></li>
                                        <li><a routerLink="">Thriller</a></li>
                                        <li><a routerLink="">Adventure</a></li>
                                        <li><a routerLink="">Biography</a></li>
                                        <li><a routerLink="">Action</a></li>
                                        <li><a routerLink="">Family</a></li>
                                        <li><a routerLink="">Sci-Fi</a></li>
                                        <li><a routerLink="">Romance</a></li>
                                        <li><a routerLink="">Drama</a></li>
                                        <li><a routerLink="">Comedy</a></li>
                                    </ul>
                                </div>
                                <div class="item">
                                    <h4>Latest Trailer</h4>
                                    <div class="">
                                        <iframe width="400" height="250" src="https://www.youtube.com/embed/sfM7_JLk-84"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link main dropdown-toggle menu-clicked" id="navbardrop" data-toggle="dropdown">Cinema</a>
                <div class="dropdown-menu shopping-tools">
                    <div class="container">
                        <div class="row shopping">
                            <div class="col">
                                <div class="cinema-menu">
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/ee621ee05dcd4565caead4f29421b41e.png"
                                                alt="">
                                            CGV
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/f32670fd0eb083c9c4c804f0f3a252ed.png"
                                                alt="">
                                            BHD Star Cineplex
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/e520781386bd5436e94d6e15e193a005.png"
                                                alt="">
                                            Galaxy Cinema
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/1721cfa98768f300c03792e25ceb0191.png"
                                                alt="">
                                            Cinestar
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/9b240f96a233bb43203ee514a21a6004.png"
                                                alt="">
                                            DDCinema
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/7b078639bd8fdb09dd83652d207c7b90.png"
                                                alt="">
                                            Mega GS
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2019/10/9ff07a03371c4a09260309faa32caa55.jpg"
                                                alt="">
                                            DCine
                                        </a>
                                    </div>
                                    <div>
                                        <a routerLink="#">
                                            <img src="https://s3img.vcdn.vn/123phim/2018/09/404b8c4b80d77732e7426cdb7e24be20.png"
                                                alt="">
                                            Lotte Cinema
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col">
                                <a routerLink="#">Đặt vé theo rạp</a>
                                <a routerLink="#">Rạp sắp mở</a>
                                <a routerLink="#">Tìm đường đến rạp</a>
                                <a routerLink="#">Chương trình hội viên</a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link main">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link main">Contact</a>
            </li>
        </ul>

        <div class="search">
            <app-header-search></app-header-search>
            <app-header-account-menu></app-header-account-menu>
        </div>
    </nav>
</header>