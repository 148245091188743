import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-account-menu',
  templateUrl: './header-account-menu.component.html',
  styleUrls: ['./header-account-menu.component.scss'],
})
export class HeaderAccountMenuComponent implements OnInit {

  activeTabId;
  isLogin: boolean;
  isAdmin = false;

  constructor(
    // tslint:disable-next-line:variable-name
    private _authService: AuthService,
    private modalService: NgbModal) {
    this.isLogin = _authService.isLogin;
  }

  ngOnInit(): void {
    this.isLogin = this._authService.isLogin;
    this._authService.isLoginChange.subscribe((value: boolean) => {
      this.isLogin = value;
    });

    // check if this is admin account
    if (localStorage.getItem('credentials') && JSON.parse(localStorage.getItem('credentials')).maLoaiNguoiDung === 'QuanTri') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  open(content, activeTabId) {
    this.activeTabId = activeTabId;
    this.modalService.open(content, { size: 'lg', centered: false });
  }

  logout() {
    this._authService.logout();
  }

  changeLogin() {
    this._authService.changeLogin();
  }

}
