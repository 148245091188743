import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    // tslint:disable-next-line:variable-name
    private _http: HttpClient,
  ) { }

  signIn(body) {
    return this._http.post('https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/DangNhap', body);
  }

  signUp(signUpUser) {
    return this._http.post('https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/DangKy', signUpUser);
  }

  updateAccount(userData, headers) {
    return this._http.put(
      'https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/CapNhatThongTinNguoiDung', userData, headers
    );
  }

  getAccountData(userID, headers) {
    return this._http.post(
      'https://movie0706.cybersoft.edu.vn/api/QuanLyNguoiDung/ThongTinTaiKhoan', userID, headers
    );
  }
}
