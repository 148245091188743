<div class="search-form">
    <ng-autocomplete #searchMovies *ngIf="nowShowingMovies" class="searchInput" [data]="nowShowingMovies"
        [searchKeyword]="keyword" placeHolder="Search movies" (selected)='selectEvent($event)'
        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>
    <i class="fas fa-search"></i>

    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.tenPhim"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>