import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './../../../services/auth.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { AccountService } from './../../../services/account.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-profile-index',
  templateUrl: './account-profile-index.component.html',
  styleUrls: ['./account-profile-index.component.scss']
})
export class AccountProfileIndexComponent implements OnInit {
  @Input() activeTabId;
  accountData;
  userID = { taiKhoan: JSON.parse(localStorage.getItem('credentials')).taiKhoan };

  formAccount: FormGroup;
  submitted = false;
  error: string;
  message: string;

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    })
  };

  constructor(
    // tslint:disable:variable-name
    private _accountService: AccountService,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private modalService: NgbModal
  ) {
    this._authService.passMessage.subscribe(msg => {
      this.message = msg;
    });
  }

  ngOnInit(): void {
    // get account data
    this._accountService.getAccountData(this.userID, this.httpOptions)
      .subscribe(
        (res) => {
          console.log(res);
          this.accountData = res;
        },
        (err) => {
          console.log(err);
        }
      );


    // set the form
    this.formAccount = this._formBuilder.group({
      taiKhoan: new FormControl('', [Validators.required]),
      matKhau: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      hoTen: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      soDt: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
    });

  }

  get fAcc() { return this.formAccount.controls; }

  updateAccount() {
    this.submitted = true;

    if (this.formAccount.invalid) {
      this.formAccount.markAllAsTouched();
      return;
    }

    const userData = { ...this.formAccount.value, maNhom: 'GP09', maLoaiNguoiDung: 'KhachHang' };

    this._accountService.updateAccount(userData, this.httpOptions)
      .subscribe(
        (val) => {
          this._authService.updateMessage('Your account has been updated!');
        },
        (err) => {
          console.log(err);
          this.error = err.error;
        }
      );
  }

  close() {
    this.modalService.dismissAll();
  }

}
