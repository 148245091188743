import { ToastService } from './toast.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogin: boolean;
  isLoginChange: Subject<boolean> = new Subject<boolean>();
  passMessage: Subject<string> = new Subject<string>();

  // tslint:disable:variable-name
  constructor(
    private _http: HttpClient,
    private _toast: ToastService
  ) {
    this.isLogin = false;
  }

  isLoggedIn() {
    return Boolean(this.getToken());
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  logout() {
    localStorage.clear();
    this.isLogin = false;
    this.isLoginChange.next(this.isLogin);
    this._toast.open('You have been loged out!', 'success', 3000);
  }

  changeLogin() {
    this.isLogin = this.isLoggedIn();
    this.isLoginChange.next(this.isLogin);
  }

  updateMessage(msg: string) {
    this.passMessage.next(msg);
  }
}
