<div class="modal-body">
    <button class="modal-close" (click)="close()"></button>
    <div class="container">
        <div class="row">
            <div class="col-5 p-5">
                <h4>Enjoy your Movix</h4>
                <p *ngIf="!message; else passMsg">Join us, be a Movixer and book movies ticket fast.</p>
                <ng-template #passMsg>
                    <p class="msg"><i class="fas fa-info-circle"></i> {{message}}</p>
                </ng-template>
                <div class="error" *ngIf="error"><i class="fas fa-exclamation-triangle"></i> {{error}}</div>
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Login</a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="formSignin" (submit)="handleLogin()">
                                <div class="form-group">
                                    <input formControlName="taiKhoan" placeholder="Username" type="text"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && fLgi.taiKhoan.errors }" required>
                                </div>
                                <div class="form-group">
                                    <input formControlName="matKhau" placeholder="Password" type="password"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && fLgi.matKhau.errors }" required>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn_red2">Login</button>
                                    <span class="float-right forgotPasw">Forgot password?</span>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Register</a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="formSignup" (submit)="handleRegister()">
                                <div class="form-group">
                                    <input type="text" placeholder="Username" formControlName="taiKhoan"
                                        [ngClass]="{ 'is-invalid': submittedReg && fReg.taiKhoan.errors }"
                                        class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="Password" formControlName="matKhau"
                                        [ngClass]="{ 'is-invalid': submittedReg && fReg.matKhau.errors }"
                                        class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="email" placeholder="Email address" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submittedReg && fReg.email.errors }"
                                        class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Full Name" formControlName="hoTen"
                                        [ngClass]="{ 'is-invalid': submittedReg && fReg.hoTen.errors }"
                                        class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Phone Number" formControlName="soDt"
                                        [ngClass]="{ 'is-invalid': submittedReg && fReg.soDt.errors }"
                                        class="form-control">
                                </div>
                                <div class="form-group">
                                    <button class="btn_red2" type="submit">Register</button>
                                    <span class="float-right ssLogin">
                                        Signup with<br><i class="fab fa-facebook-square"></i> <i
                                            class="fab fa-twitter-square"></i> <i class="fab fa-google"></i></span>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>

            </div>
            <div class="col-7 loginBG" [style.background-image]="'url(' + bgImgURL + ')'">
            </div>
        </div>
    </div>
</div>