import { ToastService } from './../../../services/toast.service';
import { AccountService } from './../../../services/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
// import PropTypes from './../../../../assets/images'


@Component({
  selector: 'app-login-register-form',
  templateUrl: './login-register-form.component.html',
  styleUrls: ['./login-register-form.component.scss']
})
export class LoginRegisterFormComponent implements OnInit, OnDestroy {
  formSignin: FormGroup;
  formSignup: FormGroup;
  submitted = false;
  submittedReg = false;
  @Input() activeTabId;
  isLogin: boolean;
  error: string;
  message: string;
  bgImgList = ['wal1.jpg', 'wal2.jpg', 'wal3.jpg', 'wal4.jpg', 'wal5.jpg', 'wal6.jpg', 'wal7.jpg'];
  bgImgURL = document.location.origin + '/assets/images/' + this.bgImgList[Math.floor(Math.random() * this.bgImgList.length)];


  constructor(
    // tslint:disable:variable-name
    private _authService: AuthService,
    private _accountService: AccountService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _toast: ToastService
  ) {
    this._authService.passMessage.subscribe(msg => {
      this.message = msg;
    });
  }

  ngOnInit(): void {
    this.isLogin = this._authService.isLoggedIn();

    this.formSignin = this._formBuilder.group({
      taiKhoan: new FormControl('', [Validators.required]),
      matKhau: new FormControl('', [Validators.required])
    });

    this.formSignup = this._formBuilder.group({
      taiKhoan: new FormControl('', [Validators.required]),
      matKhau: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      hoTen: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      soDt: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
    });
  }

  get fLgi() { return this.formSignin.controls; }
  get fReg() { return this.formSignup.controls; }

  handleLogin() {
    this.submitted = true;
    // tslint:disable-next-line:curly
    if (this.formSignin.invalid) return;

    this._accountService.signIn(this.formSignin.value)
      .subscribe(
        (res: any) => {
          // console.log(res);
          localStorage.setItem('credentials', JSON.stringify(res));
          localStorage.setItem('accessToken', res.accessToken);
          this._toast.open('You are login now!', 'success', 3000);
          this.modalService.dismissAll();
        },
        (err) => {
          console.log(err);
          this.error = err.error;
        }
      );
  }

  handleRegister() {
    this.submittedReg = true;
    // console.log(this.formSignup);
    if (this.formSignup.invalid) {
      this.formSignup.markAllAsTouched();
      return;
    }

    const signUpUser = { ...this.formSignup.value, maNhom: 'GP09', maLoaiNguoiDung: 'KhachHang' };
    // console.log(signUpUser);

    this._accountService.signUp(signUpUser).subscribe(
      (val) => {
        // console.log(val);
        this.modalService.dismissAll();
        this.modalService.open(LoginRegisterFormComponent, { size: 'lg', centered: false });
        this._authService.updateMessage('Successfully registered!');
      },
      (err) => {
        console.log(err);
        this.error = err.error;
      }
    );
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    // this._authService.passMessage.unsubscribe();
  }

}
